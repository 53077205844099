import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class MotorcycleInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Motorcycle Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Whether your ride a motorcycle for your commute or leisure, the chances of a accident are high. This is why you need to ensure you have the right coverages in place with an affordable rate in Louisiana. Contact us for a quote!" }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Motorcycle rider on the highway" />
        <Callout
          title="Motorcycle Insurance"
          content="Roads are being filled with more and more distracted drivers. Whether you ride a motorcycle for your commute or leisure, the chances of an accident are high. This is why you need to ensure you have the right coverages in place, but at an affordable rate in Louisiana."
        />
        <div className="site-body page-content">
          <h2>What Motorcycle Insurance Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Bodily Injury.</strong> Covers you for bodily injury or death from an accident for which you’re at fault and often covers associated legal defense expenses.</li>
          <li><strong>Property Damage.</strong> Protects you if your bike accidentally damages another person or business’s property.</li>
          <li><strong>Medical Payments.</strong> Whether you are at fault or not, this usually pays medical expenses of the driver and passengers on the bike that are in an accident.</li>
          <li><strong>Uninsured Motorists.</strong> This pays for injuries and sometimes certain property damage when involved in an accident with a driver who is uninsured. This will also usually cover hit-and-run accidents.</li>
          <li><strong>Comprehensive.</strong> This covers motorcycle damage resulting from flood, fire, vandalism, theft and other covered perils.</li>
          <li><strong>Collision.</strong> Pays for the damage to a motorcycle when it is hit or hits another object.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="Approximately ¾ of motorcycle accidents involve collisions with another vehicle, most often a passenger automobile."
            source="HG Legal"
          />
          <h2>What Motorcycle Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li><strong>Personal Belongings.</strong> Comprehensive insurance will cover the damages to your motorcycle if it’s stolen or vandalized, but not any items on the bike or in a compartment. Normally this will be covered under your homeowners or renters insurance policy.</li>
          <li><strong>Individuals not on the policy that live with you.</strong> Generally motorcycle insurance will only cover individuals on the policy and those who don’t live with you, but have permission to use the bike.</li>
          <li><strong>Motorcycle Loan for Totaled Bike.</strong> Collision and Comprehensive insurance will cover your bike’s market value minus your deductible. So if your motorcycle is worth less than you owe, you will need to make up the difference. Gap Insurance can be used to mitigate this risk.</li>
          <li><strong>Custom Parts and Equipment.</strong> If your motorcycle is damaged or stolen, motorcycle insurance will not cover the cost of custom add-ons. However, many policies offer an optional policy to cover this.</li>
          <li><strong>Business Use.</strong> If you use your bike for business purposes, you will need to purchase a commercial policy since these are excluded under most personal policies.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a Motorcycle Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default MotorcycleInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "motorcycle-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-21.jpg" }) {
      ...mainBg
    }
  }
`;
